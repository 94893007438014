import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PortfolioBar from "../modules/PortfolioBar";
import { CHAIN_ID, Client, ClientFactory, DefaultProviderUrls } from "@massalabs/massa-web3";
import PortfolioList from "../modules/PortfolioList";

const Portfolio: React.FC = () => {
  const {address} = useParams();
  const [addressValid, setAddressValid] = useState<boolean | undefined>(undefined);
  const [web3Client, setWeb3Client] = useState<Client>();

  const initClient = async () => {
    const client = await ClientFactory.createDefaultClient(DefaultProviderUrls.MAINNET, CHAIN_ID.MainNet);
    setWeb3Client(client);
  }
  
  const checkAddress = async (address: string) => {
    setAddressValid(undefined);
    if(!web3Client) return;
    try {
      const validAddress = await web3Client.wallet().getAccountBalance(address);
      if(validAddress !== null) setAddressValid(true);
      else setAddressValid(false);
    } catch {
      setAddressValid(false);
    }
  }

  useEffect(() => {
    initClient();
  }, [address]);

  useEffect(() => {
    if(address) checkAddress(address);
  }, [web3Client]);

  return (
    <div className="mt-3 mb-5">
      <div className="text-2xl truncate">Portfolio <span className="text-sm">mainnet</span></div>
      <PortfolioBar />
      {address && web3Client && (addressValid ?
        <div>
          <div className="text-xl mb-5 truncate"><kbd>{address}</kbd></div>
          <PortfolioList address={address} web3Client={web3Client} />
        </div>
      :
        (addressValid !== undefined && <div className="text-red-600 bg-red-100 rounded p-2">Error: Address not valid..</div>)
      )}
    </div>
  );
};

export default Portfolio;
